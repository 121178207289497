import Vue from "vue";
import { notifyInfo } from "@/components/Notification";

import { getAPI } from "@/api/axios-base";
import { isDevelop, twilioTokenUrl } from "@/enviorment";
import { Connection, Device } from "twilio-client";
import { mapMutations } from "vuex";
import { MUT_DEVICE_READY } from "@/store/mutations/types";
import TwilioError from "twilio-client/es5/twilio/errors/twilioError";
import i18n from "@/i18n";

const JWTtokenExpiredCode = 31205;

/**
 * To provide acces to Twilio Device and Connection clients
 */
const DeviceProvider = Vue.extend({
  provide() {
    return {
      callCenterProvider: (this as any).toProvide,
    };
  },
  data() {
    return {
      toProvide: {
        showSidebarCallInProgress: null, // Llamadas en curso
        showSidebarOnlineUsers: null, // Agentes online
        twilioDeviceProvider: new Device(),
        incomingCallConnection: null,
        outgoingConnection: null,
        callInProgressConection: null,
        fromNumber: "",
        toNumber: "",
        // For incoming call UI
        incomingCall: false,
        progressCall: false,
      },
    };
  },
  methods: {
    ...mapMutations([MUT_DEVICE_READY]),
    onIncomingCall(connection: Connection): void {
      // Poner a disposicion la conexion entrante
      (this as any).toProvide.incomingCallConnection = connection;
      (this as any).toProvide.incomingCall = true;
      (this as any).toProvide.fromNumber = (
        this as any
      ).toProvide.incomingCallConnection.parameters.From;
      isDevelop &&
        console.log(
          "Incomming",
          (this as any).toProvide.incomingCallConnection
        );
    },
    inProgresscall(connection: Connection) {
      (this as any).toProvide.callInProgressConection = connection;
      (this as any).toProvide.progressCall = true;
      isDevelop &&
        console.log("Accept", (this as any).toProvide.callInProgressConection);
    },
    onError(error: TwilioError) {
      // If the Twilio token expire get request other
      if (error.code == JWTtokenExpiredCode) {
        (this as any).toProvide.twilioDeviceProvider.destroy();
        notifyInfo(i18n.t("notificationTokenExpired"));
        (this as any).initDevice().then(() => {
          notifyInfo(i18n.t("notificationEstablished"));
        });
      }

      isDevelop && console.log("Some error", error);
    },
    onReady(): void {
      (this as any)[MUT_DEVICE_READY](true);
      notifyInfo(
        i18n.t("crmCallCenter.nofificationDeviceReady"),
        i18n.t("crmCallCenter.nofificationDeviceReadyTitle")
      );
    },
    async initDevice(): Promise<any> {
      // 1- Pedir token
      getAPI
        .post(twilioTokenUrl)
        .then((response) => {
          const token = response.data.token;
          // 2- Configurar el servicio
          (this as any).toProvide.twilioDeviceProvider.setup(token, {
            codecPreferences: ["opus", "pcmu"],
            fakeLocalDTMF: true,
            enableRingingState: true,
            debug: process.env.NODE_ENV != "production",
          });

          // 3- Events binding
          (this as any).toProvide.twilioDeviceProvider.on(
            "incoming",
            (this as any).onIncomingCall
          );
          (this as any).toProvide.twilioDeviceProvider.on(
            "accept",
            (this as any).inProgresscall
          );
          (this as any).toProvide.twilioDeviceProvider.on(
            "error",
            (this as any).onError
          );
          (this as any).toProvide.twilioDeviceProvider.on(
            "ready",
            (this as any).onReady
          );
        })
        .catch((error) => {
          isDevelop && console.log(error);
        });
    },
  },
});

export default DeviceProvider;

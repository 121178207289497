/* eslint-disable */
import { getAPI } from "@/api/axios-base";
import moment from "moment";
import {
  notificationError,
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";
import { RequestPayment, RequestPayments } from "@/models/Payments";

import store from "@/store";
import { uniqBy } from "lodash";

const LIST = "/users/getDevices/100/0";
const GETONE = "/users/getOneDevice/";
const CREATE = "/users/createDevice";
const DELETE = "/users/removeDevice/";
const UPDATE = "/users/unlockDevice/";

const GETLeads = "/social/filter";

export async function actGetDevices({ commit }: any): Promise<any> {
  try {
    commit("mutSetLoading", true);
    const responseData = (await getAPI(LIST)).data.result;

    commit("mutSetDevices", responseData);
    commit("mutSetLoading", false);
  } catch (error: any) {
    commit("mutSetLoading", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}
export async function actGetOneDevice(
  { commit }: any,
  uuid: string
): Promise<any> {
  try {
    commit("mutSetLoading", true);
    const responseData = (await getAPI(GETONE + uuid)).data;

    commit("mutSetDeviceSelect", responseData);
    commit("mutSetLoading", false);
  } catch (error: any) {
    commit("mutSetLoading", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export async function actDeviceCreate(
  { commit, dispatch }: any,
  { deviceName, type }: any
): Promise<any> {
  commit("mutSetLoading", true);
  await getAPI.post(CREATE, { deviceName: deviceName, type: type });
  await dispatch("actGetDevices");
  commit("mutSetLoading", false);
}
export async function actDeviceRemove(
  { commit, dispatch }: any,
  uuid: string
): Promise<any> {
  try {
    commit("mutSetLoading", true);
    await getAPI.delete(DELETE + uuid);
    await dispatch("actGetDevices");
    commit("mutSetLoading", false);
  } catch (error: any) {
    commit("mutSetLoading", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}
export async function actDeviceUnlock(
  { commit, dispatch }: any,
  uuid: string
): Promise<any> {
  try {
    commit("mutSetLoading", true);
    await getAPI.put(UPDATE + uuid);
    await dispatch("actGetDevices");
    commit("mutSetLoading", false);
  } catch (error: any) {
    commit("mutSetLoading", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}
export async function actConverToPatient(
  { commit }: any,
  uuid: any
): Promise<any> {
  try {
    commit("mutSetLoading", true);
    const responseData = (await getAPI.put("/social/convertToPatient/" + uuid))
      .data.fullname;
    notifySuccess(`${responseData} has been converted to patient`);
    commit("mutSetLoading", false);
  } catch (error: any) {
    commit("mutSetLoading", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}
export async function actAddDescription(
  { commit }: any,
  req: any
): Promise<any> {
  try {
    commit("mutSetLoading", true);
    await getAPI.put("/twiliocloud/updateCallDescription/", req);
    notifySuccess(`The call was added successfully`);
    commit("mutSetLoading", false);
  } catch (error: any) {
    commit("mutSetLoading", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}
export async function actAddNote({ commit }: any, req: any): Promise<any> {
  try {
    commit("mutSetLoading", true);
    await getAPI.post("/social/createLeadNote/", req);
    notifySuccess(`The note was added successfully`);
    commit("mutSetLoading", false);
  } catch (error: any) {
    commit("mutSetLoading", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}
export async function actAddLead({ commit }: any, req: any): Promise<any> {
  try {
    commit("mutSetLoading", true);
    await getAPI.post("/social/createLead/", req);
    notifySuccess(`The Lead was added successfully`);
    commit("mutSetLoading", false);
  } catch (error: any) {
    commit("mutSetLoading", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export async function actAsignToLead({ commit }: any, req: any) {
  try {
    commit("mutSetLoading", true);
    await getAPI.put("/social/asingLeadTo", req);
    notifySuccess(`The Lead was assigned successfully`);
    commit("mutSetLoading", false);
  } catch (error: any) {
    commit("mutSetLoading", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}
export async function actMultiAsignToLead({ commit }: any, req: any) {
  try {
    commit("mutSetLoading", true);
    for (let index = 0; index < req.items.length; index++) {
      const element = {
        leadUuid: req.items[index].uuid,
        asingTo: req.asingTo,
      };
      getAPI.put("/social/asingLeadTo", element);
    }
    notifySuccess(`The Leads was assigned successfully`);
    commit("mutSetLoading", false);
  } catch (error: any) {
    commit("mutSetLoading", false);
    notifyError(
      error.response?.data.response.message[0] ||
        error.response?.data.message ||
        error.response?.data.details
    );
  }
}

export async function actLeadFiler({ commit }: any, req: any) {
  try {
    commit("mutSetLoad", true);
    req = { ...req, interval: { limit: 100, offset: 0 } };
    const responseData = (await getAPI.post("/social/filter", req)).data;
    commit("mutSetLoad", false);
    commit("mutSetLeads", responseData.result);
  } catch (error: any) {
    commit("mutSetLoad", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export async function actGetLeads(
  { commit }: any,
  reqrange: any
): Promise<any> {
  try {
    commit("mutSetLoad", true);
    const responseData = (await getAPI.post(GETLeads, reqrange)).data;
    commit("mutSetLoad", false);
    commit("mutSetLeads", responseData.result);
    commit("mutSetTotalLeads", responseData.count);
  } catch (error: any) {
    commit("mutSetLoad", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}
/*************Payments***********************/
const GETPAYMENTSUMARY = "/payment/summary";
const GETPAYMENTS = "/payment/filterList";

export async function actGetPaymentSumary({ commit }: any) {
  try {
    commit("mutSetLoad", true);
    const date1 = new Date();
    date1.setUTCHours(0, 0, 1);
    const date2 = new Date();
    date2.setUTCHours(23, 59, 59);
    const req: RequestPayment = {
      date1: date1.toISOString(),
      date2: date2.toISOString(),
    };
    const responseData = (await getAPI.post(GETPAYMENTSUMARY, req)).data;
    commit("mutSetPayments", responseData);
    commit("mutSetLoad", false);
  } catch (error: any) {
    commit("mutSetLoad", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}
function cleanNull(obj: any) {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
}

export async function actGetPayments({ commit }: any, requestPaym: any) {
  try {
    commit("mutSetLoad", true);

    const rrange = requestPaym.range
      ? requestPaym.range
      : { limit: 50, offset: 0 };
    let req: RequestPayments = {
      employeeUuid: requestPaym?.employeeUuid
        ? requestPaym?.employeeUuid
        : null,
      patientUuid: requestPaym?.patientUuid ? requestPaym?.patientUuid : null,
      type: requestPaym.type ? requestPaym.type : undefined,
      status: requestPaym?.status ? requestPaym?.status : null,
      dateRange: {
        date1: requestPaym.dates[0],
        date2: requestPaym.dates[1],
      },
      range: rrange,
    };

    req = cleanNull(req);
    const responseData = (await getAPI.post(GETPAYMENTS, req)).data;
    store.commit("mutTotalItems", responseData.count);
    commit("mutSetPayments", responseData.result);
    commit("mutSetLoad", false);
  } catch (error: any) {
    commit("mutSetLoad", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export async function actFirstPayments({ commit }: any, requestPaym: any) {
  try {
    commit("mutSetLoad", true);

    const resp = (
      await getAPI.post("/sprocedures/getFirstPayments", requestPaym)
    ).data;

    commit("mutTotalFirst", resp.count);
    commit("mutSetFirstPayments", resp.result);
    commit("mutSetLoad", false);
  } catch (error: any) {
    commit("mutSetLoad", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

//**Clock in */

export async function actAddClockIn({ commit }: any, req: any) {
  try {
    commit("mutSetLoad", true);
    await getAPI.post("/clocking/insert-clocking", req);
    commit("mutSetLoad", false);
  } catch (error: any) {
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);

    commit("mutSetLoad", false);
  }
}

export async function actFilterClock({ commit }: any, req: any) {
  try {
    commit("mutClockin", []);

    let rrange = req.range ? req.range : { limit: 15, offset: 0 };
    rrange = rrange.limit != 0 ? rrange : { limit: 15, offset: 0 };
    let reqt: any = {
      employeeUuid: req?.employeeUuid ? req?.employeeUuid : null,
      status: req?.status ? req?.status : null,
      dateDange: { date1: req.dates[0], date2: req.dates[1] },
      range: rrange,
    };

    reqt = cleanNull(reqt);
    commit("mutSetLoad", true);
    const ResponseData = (await getAPI.post("/clocking/filter", reqt)).data;
    store.commit("mutTotalItems", ResponseData.count);
    commit("mutClockin", ResponseData.result);
    commit("mutSetLoad", false);
  } catch (error: any) {
    commit("mutSetLoad", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}
export async function actFilterAmount({ commit }: any, req: any) {
  try {
    let date1 = new Date();
    let date2 = new Date();
    if (req) {
      date1 =
        req?.dateDange[0] != "" ? new Date(req?.dateDange[0]) : new Date();
      date2 =
        req?.dateDange[1] != "" ? new Date(req?.dateDange[1]) : new Date();
    }

    let rrange = req.range ? req.range : { limit: 15, offset: 0 };
    rrange = rrange.limit != 0 ? rrange : { limit: 15, offset: 0 };
    let reqt: any = {
      employeeUuid: req?.employeeUuid ? req?.employeeUuid : null,
      dateDange: {
        date1: date1.toISOString(),
        date2: date2.toISOString(),
      },
      range: rrange,
    };

    reqt = cleanNull(reqt);
    commit("mutSetLoad", true);
    const ResponseData = (await getAPI.post("/clocking/amoutDue", reqt)).data;

    commit("mutamountSelect", ResponseData.result);
    commit("mutSetLoad", false);
  } catch (error: any) {
    commit("mutSetLoad", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}
export async function actFilterDay({ commit, dispatch }: any, req: any) {
  try {
    commit("mutSetLoad", true);
    /*  let date1 = new Date();
    let date2 = new Date();
    if (req) {
      date1 =
        req?.dateDange[0] != "" ? new Date(req?.dateDange[0]) : new Date();
      date2 =
        req?.dateDange[1] != "" ? new Date(req?.dateDange[1]) : new Date();
    } */
    // const day1 = moment(req.dateDange[0]);
    let employee = null;
    if (req.employee != null && req.employee != undefined) {
      employee = req.employee;
      delete req.employee;
    }
    let rrange = req.range ? req.range : { limit: 15, offset: 0 };
    rrange = rrange.limit != 0 ? rrange : { limit: 15, offset: 0 };
    let reqt: any = {
      employeeUuid: req?.employeeUuid ? req?.employeeUuid : null,
      dateDange: { date1: req.dateDange[0], date2: req.dateDange[1] },
    };

    reqt = cleanNull(reqt);
    commit("mutSetLoad", true);
    const ResponseData = (await getAPI.post("/clocking/groupByDayFilter", reqt))
      .data;
    const reverseData: any = [];
    ResponseData.forEach((res: any) => {
      const temp = res;
      /*  if (temp.logs != undefined && temp.logs.length != 0) {
        const lg = temp.logs;

        console.log(lg, "antes");

        const sort = lg.sort(
          (a: any, b: any) => moment(a.createdAt) > moment(b.createdAt)
        );
        temp.logs = sort;
        console.log(sort, "sort");
      } */
      reverseData.push(temp);
    });

    const resday = await dispatch("actfilDays", {
      params: reqt.dateDange,
      employee: employee,
      res: reverseData,
    });

    commit("mutClockDays", resday);

    commit("mutSetLoad", false);
  } catch (error: any) {
    commit("mutSetLoad", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}
export async function actFilterOvertimes({ commit }: any, req: any) {
  try {
    commit("mutSetLoad", true);
    let date1 = new Date();
    let date2 = new Date();
    if (req) {
      date1 =
        req?.dateDange[0] != "" ? new Date(req?.dateDange[0]) : new Date();
      date2 =
        req?.dateDange[1] != "" ? new Date(req?.dateDange[1]) : new Date();
    }

    let rrange = req.range ? req.range : { limit: 15, offset: 0 };
    rrange = rrange.limit != 0 ? rrange : { limit: 15, offset: 0 };
    let reqt: any = {
      employeeUuid: req?.employeeUuid ? req?.employeeUuid : null,
      dateDange: {
        date1: date1.toISOString(),
        date2: date2.toISOString(),
      },
      range: rrange,
    };

    reqt = cleanNull(reqt);
    commit("mutSetLoad", true);
    const ResponseData = (
      await getAPI.post("/clocking/extra-filter-list", reqt)
    ).data;
    commit("mutSetTotalOvertimes", ResponseData.count);
    commit("mutOvertimes", ResponseData.result);
    commit("mutSetLoad", false);
  } catch (error: any) {
    commit("mutSetLoad", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export async function actAddOvertime({ commit }: any, req: any) {
  try {
    commit("mutSetLoad", true);
    const responseData = (await getAPI.post("/clocking/programOverTime", req))
      .data;
    commit("mutSetLoad", false);
    return responseData;
  } catch (error: any) {
    commit("mutSetLoad", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export function actfilDays({ commit }: any, { params, employee, res }: any) {
  const day1 = moment(params.date1);
  const dayfinal = moment(params.date2);

  const arrayday: Array<string> = [];
  const arrayr: Array<any> = [];

  const arraynames: Array<any> = [];

  const dt = day1;
  arrayday.push(day1.toISOString());
  for (
    let index = 0;
    index < dayfinal.diff(moment(params.date1), "days");
    index++
  ) {
    const d = dt.add(1, "day");
    arrayday.push(d.toISOString());
  }

  const arraynamecreated: any[] = [];
  res.map((r: any) => {
    arraynamecreated.push({
      name: r.name,
      entry: r.logs[0].startDate,
      uuid: r.uuid,
      created: r.created,
      pxh: r.payXhour,
    });
  });

  let sortedArray = arraynamecreated.sort(
    (a, b) => moment(a.entry).unix() - moment(b.entry).unix()
  );

  sortedArray = uniqBy(sortedArray, "uuid");

  arrayday.forEach((element: any) => {
    if (res != null && res != undefined && res.length != 0) {
      sortedArray.forEach((an: any) => {
        const pos = arrayr.filter(
          (arr: any) => arr.name == an.created && arr.created == element
        );

        if (pos.length == 0) {
          arrayr.push({
            created: element,
            name: an.name,
            uuid: an.uuid,
            payXhour: an.pxh,
            possition: -1,
            notwork: true,
          });
        }
      });
    } else if (employee != null && employee != undefined) {
      arrayr.push({
        created: element,
        name: employee.fullname,
        uuid: employee.uuid,
        payXhour: employee.payXhour,
        possition: -1,
        notwork: true,
      });
    }
  });

  for (let index = 0; index < arrayr.length; index++) {
    const ele = arrayr[index];
    const fecCrea = ele.created.split("T")[0];
    for (let i = 0; i < res.length; i++) {
      const element = res[i];
      const creat = moment(element.created).toISOString().split("T")[0];
      const nm = element.name;

      if (fecCrea == creat && ele.name == nm) {
        arrayr[index] = element;
      }
    }
  }

  commit("mutSetLoad", false);

  return arrayr;
}

export async function actFilterPresence({ commit }: any, req: any) {
  try {
    commit("mutSetLoad", true);
    let date1 = new Date();
    let date2 = new Date();
    if (req) {
      date1 =
        req?.dateDange[0] != "" ? new Date(req?.dateDange[0]) : new Date();
      date2 =
        req?.dateDange[1] != "" ? new Date(req?.dateDange[1]) : new Date();
    }

    let rrange = req.range ? req.range : { limit: 15, offset: 0 };
    rrange = rrange.limit != 0 ? rrange : { limit: 15, offset: 0 };
    let reqt: any = {
      employeeUuid: req?.employeeUuid ? req?.employeeUuid : null,
      dateRange: {
        date1: date1.toISOString(),
        date2: date2.toISOString(),
      },
      range: rrange,
      entry: req.entry != undefined ? req.entry : null,
    };

    reqt = cleanNull(reqt);
    commit("mutSetLoad", true);
    if (reqt.employeeUuid != undefined) {
      let totalResponse = (
        await getAPI.post(`presence/filterList?all=true`, reqt)
      ).data.result;
      if (totalResponse.length > 0) {
        totalResponse = totalResponse.reverse();
        let totalhours = 0;
        let c = 1;
        for (let index = 0; index < totalResponse.length; index += 2) {
          let totaltem = 0;
          const fechain = moment(totalResponse[index].createdAt);
          if (c < totalResponse.length) {
            const fechaout = moment(totalResponse[c].createdAt);
            totaltem = fechaout.diff(fechain, "minute");

            totalhours = totalhours + totaltem;
            c += 2;
          }
        }

        commit("mutTotalxemployee", totalhours);
      } else {
        commit("mutTotalxemployee", 0);
      }
    } else {
      commit("mutTotalxemployee", 0);
    }
    const ResponseData = (await getAPI.post("/presence/filterList", reqt)).data;
    commit("mutSetTotalPresences", ResponseData.count);

    commit("mutPrecences", ResponseData.result);
    commit("mutSetLoad", false);
  } catch (error: any) {
    commit("mutSetLoad", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

/*Quotes Generals */
export async function actGetQuotes({ commit }: any, reqQuotes: any) {
  try {
    const rrange = reqQuotes.range ? reqQuotes.range : { limit: 50, offset: 0 };
    let req: RequestPayments = {
      employeeUuid: reqQuotes?.employeeUuid ? reqQuotes?.employeeUuid : null,
      patientUuid: reqQuotes?.patientUuid ? reqQuotes?.patientUuid : null,
      aproved:
        reqQuotes?.aproved != undefined || reqQuotes?.aproved != null
          ? reqQuotes?.aproved
          : null,
      quote_type:
        reqQuotes?.quote_type != null || reqQuotes?.quote_type != undefined
          ? reqQuotes?.quote_type
          : null,
      dateRange: {
        date1: reqQuotes.dates[0],
        date2: reqQuotes.dates[1],
      },
      range: rrange,
    };

    req = cleanNull(req);
    const responseData = (
      await getAPI.post("/sprocedures/filterQuoteList", req)
    ).data;
    store.commit("mutTotalItems", responseData.count);
    commit("mutSetQuotes", responseData.result);
    commit("mutSetLoad", false);
  } catch (error: any) {
    commit("mutSetLoad", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

//Sell Accesories

export async function actCreateSale({ commit }: any, req: any) {
  try {
    commit("mutSetLoad", true);
    const responseData = (await getAPI.post("/sales/create", req)).data;
    commit("mutSetLoad", false);
    return responseData;
  } catch (error: any) {
    commit("mutSetLoad", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export async function actSalesByProduct({ commit }: any, req: any) {
  try {
    commit("mutSetLoad", true);
    const responseData = (await getAPI.post("/payment/salesByProduc", req))
      .data;
    commit("mutsalesProducts", responseData);
    commit("mutSetLoad", false);
  } catch (error: any) {
    commit("mutSetLoad", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export async function actGetSales({ commit }: any, requestSale: any) {
  try {
    commit("mutSetLoad", true);
    commit("mutsalesProducts", []);

    const rrange = requestSale.range
      ? requestSale.range
      : { limit: 50, offset: 0 };
    let req: RequestPayments = {
      employeeUuid: requestSale?.employeeUuid
        ? requestSale?.employeeUuid
        : null,
      patientUuid: requestSale?.patientUuid ? requestSale?.patientUuid : null,
      dateRange: {
        date1: requestSale.dates[0],
        date2: requestSale.dates[1],
      },
      range: rrange,
    };

    req = cleanNull(req);
    const responseData = (await getAPI.post("/sales/filterList", req)).data;
    store.commit("mutTotalItems", responseData.count);
    commit("mutsalesProducts", responseData.result);
    commit("mutSetLoad", false);
  } catch (error: any) {
    commit("mutSetLoad", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

//Refund Sale
export async function actRefundSale({ commit }: any, uuid: string) {
  try {
    commit("mutSetLoad", true);
    await getAPI.put("/sales/refound-sale/" + uuid);
    notifyInfo("Sale has been Refund");

    commit("mutSetLoad", false);
  } catch (error: any) {
    commit("mutSetLoad", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}
//Cancel Sale
export async function actCancelSale({ commit }: any, uuid: string) {
  try {
    commit("mutSetLoad", true);
    await getAPI.put("/sales/cancel-sale/" + uuid);
    notifyInfo("Sale has been cancelled");
    commit("mutSetLoad", false);
  } catch (error: any) {
    commit("mutSetLoad", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

/*Phone Numbers */
export async function actGetNumbers({ commit }: any) {
  try {
    commit("mutSetLoad", true);
    const responseData = (await getAPI("/twiliocloud/listTwillionumbers")).data;

    if (responseData != undefined) {
      const free = responseData.filter((res: any) => res.employee == null);
      if (free != undefined) {
        commit("mutPhonesFree", free);
      }

      commit("mutPhoneNumbers", responseData);
    }

    commit("mutSetLoad", false);
  } catch (error: any) {
    commit("mutSetLoad", false);

    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}
export async function actAddNumbers({ commit, dispatch }: any, number: string) {
  try {
    commit("mutSetLoad", true);
    await getAPI.post("/twiliocloud/createTwillionumber", { number: number });
    notifySuccess("Phone Number added successfully");
    dispatch("actGetNumbers");
    commit("mutSetLoad", false);
  } catch (error: any) {
    commit("mutSetLoad", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}
export async function actAssignNumber({ commit }: any, req: any) {
  try {
    commit("mutSetLoad", true);
    await getAPI.put("/twiliocloud/asignTwillionumber", req);
    notifySuccess("Phone Number has been assigned successfully");

    commit("mutSetLoad", false);
  } catch (error: any) {
    commit("mutSetLoad", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}
export async function actFreeNumber({ commit, dispatch }: any, uuid: string) {
  try {
    commit("mutSetLoad", true);
    await getAPI.put("/twiliocloud/freeTwillionumber/" + uuid);
    notifySuccess("Phone Number released successfully");
    dispatch("actGetNumbers");
    commit("mutSetLoad", false);
  } catch (error: any) {
    commit("mutSetLoad", false);
    notifyError(error.response.data.message);
  }
}
export async function actDeleteNumber({ commit, dispatch }: any, uuid: string) {
  try {
    commit("mutSetLoad", true);
    await getAPI.delete("/twiliocloud/freeTwillionumber/" + uuid);
    notifySuccess("Phone Number has been deleted");
    dispatch("actGetNumbers");
    commit("mutSetLoad", false);
  } catch (error: any) {
    commit("mutSetLoad", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}
export async function actFilterLogs({ commit }: any, req: any) {
  try {
    commit("mutSetLoad", true);
    const responseData = (await getAPI.post("/sys-logs/filter-list", req)).data;
    commit("mutSystemLogs", responseData.result);
    commit("mutSetTotalLlogs", responseData.count);
    commit("mutSetLoad", false);
  } catch (error: any) {
    commit("mutSetLoad", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

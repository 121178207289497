import { RouteConfig } from "vue-router";

const CrmHomeRoutes: Array<RouteConfig> = [
  {
    path: "",
    name: "Appointments",
    component: () => import("@/views/Appointments/Calendar/Calendar.vue"),
    meta: {
      requiresLogged: true,
    },
    props: true,
  },
  {
    path: "table-view",
    name: "Appointments table view",
    component: () => import("@/views/Appointments/AppointmentsTableView.vue"),
    meta: {
      requiresLogged: true,
    },
    props: true,
  },
  // {
  //   path: "",
  //   name: "CRM kanban",
  //   component: () => import("@/views/Appointments/Kanban.vue"),
  //   meta: {
  //     requiresLogged: true,
  //   },
  //   props: true,
  // },
  // {
  //   path: "negotiation-list",
  //   name: "CRM Negotiation list",
  //   component: () => import("@/views/Appointments/NegotiationList.vue"),
  //   meta: {
  //     requiresLogged: true,
  //   },
  //   props: true,
  // },
  // {
  //   path: "calendar",
  //   name: "CRM Calendar",
  //   component: () => import("@/views/Appointments/Calendar/Calendar.vue"),
  //   meta: {
  //     requiresLogged: true,
  //   },
  //   props: true,
  // },
  // {
  //   path: "calendar-create-negotiation/:startDate",
  //   name: "CRM Calendar create negotiation",
  //   component: () => import("@/views/Appointments/Calendar/CreateNegotiation.vue"),
  //   meta: {
  //     requiresLogged: true,
  //   },
  //   props: true,
  // },
];

export default CrmHomeRoutes;

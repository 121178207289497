import Vue from "vue";

import RecordedCall from "@/models/RecordedCall";
import prettyTime from "@/utils/prettyTime";
import { notifyInfo } from "@/components/Notification";
import { mapActions, mapState } from "vuex";

/**
 * @description Behavior for call list
 */
const CallList = Vue.extend({
  data() {
    return {
      currentRecord: {
        uuid: "66efaa8b-7d8c-4a44-8d94-24aede53f2f7",
        from: "+17869461441",
        to: "+17867551738",
      },
      dialogConfirmRemove: false,
    };
  },
  computed: {
    ...mapState("crmCallCenterModule", ["loading"]),
  },

  methods: {
    ...mapActions("crmCallCenterModule", [
      "actGetCalls",
      "actRemoveRecordedCall",
    ]),
    prettyTime,
    formatDate(date: string): string {
      return new Date(date).toLocaleString();
    },
    setRecordToRemove(record: RecordedCall) {
      this.currentRecord = record;
      this.dialogConfirmRemove = true;
    },
    remove() {
      this.actRemoveRecordedCall((this as any).currentRecord.uuid).then(() => {
        (this as any).dialogConfirmRemove = false;
        const en = this.$i18n.locale == "en";
        const bodyEn = "Call deleted successfully";
        const bodyEs = "Llamada eliminada con éxito";

        notifyInfo(en ? bodyEn : bodyEs);
      });
    },
    /**
     * Convert from client:+17863042059 to +17863042059
     */
    cleanPhone(phone: string, item?: RecordedCall): string {
      if (phone == null || phone == undefined) return "";
      if (phone.includes(":") == false) return phone;

      const phonesplit = phone.split(":")[1];
      const employee_p = item?.employee_phone?.includes(":")
        ? item.employee_phone.split(":")[1]
        : item?.employee_phone;
      if (item != undefined && phonesplit == item.employee_username) {
        return employee_p ? employee_p : phonesplit;
      }

      return phonesplit;
    },
    statusIcon(status: string): string {
      let result = "";

      switch (status) {
        case "completed":
          result = "mdi-phone-check";
          break;
        case "no-answer":
          result = "mdi-phone-cancel";
          break;
        case "queued":
          result = "mdi-phone-settings";
          break;
        case "ringing":
          result = "mdi-phone-in-talk";
          break;
        case "failed":
          result = "mdi-phone-remove";
          break;
        case "canceled":
          result = "mdi-phone-cancel";
          break;
      }

      return result;
    },
    statusColor(status: string): string {
      let result = "";

      switch (status) {
        case "completed":
          result = "green";
          break;
        case "no-answer":
          result = "amber darken-4";
          break;
        case "queued":
          result = "blue darken-4";
          break;
        case "ringing":
          result = "orange darken-4";
          break;
        case "failed":
          result = "red";
          break;
        case "busy":
          result = "gray";
          break;
        case "canceled":
          result = "red";
          break;
      }

      return result;
    },
    statusName(status: string): string {
      let result = "";

      switch (status) {
        case "completed":
          result = "Completed";
          break;
        case "no-answer":
          result = "Missed";
          break;
        case "queued":
          result = "Queued";
          break;
        case "ringing":
          result = "Ringing";
          break;
        case "failed":
          result = "Failed";
          break;
        case "busy":
          result = "Busy";
          break;
        case "canceled":
          result = "Canceled";
          break;
      }

      return result;
    },
  },
});

export default CallList;

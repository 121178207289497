import { RouteConfig } from "vue-router";
import { Permission } from "@/utils/permissions";

const WorkersRoutes: Array<RouteConfig> = [
  {
    path: "",
    name: "Workers main",
    component: () => import("@/views/Employees/Employees.vue"),
    meta: {
      permissionsRequired: [Permission.LIST_CLIENT],
      requiresLogged: true,
    },
  },
];

export default WorkersRoutes;

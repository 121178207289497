import { RouteConfig } from "vue-router";

const CallCenterRoutes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Call view",
    component: () => import("@/views/CallCenter/CallView.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "/calls",
    name: "Call view",
    component: () => import("@/views/CallCenter/CallView3cx.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "missed-call",
    name: "Missed call",
    component: () => import("@/views/CallCenter/MissedCalls.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "/calls/missed-call",
    name: "Missed call",
    component: () => import("@/views/CallCenter/MissedCalls3cx.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "recorded-calls",
    name: "Recorded callss",
    component: () => import("@/views/CallCenter/RecordedCalls.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "/calls/recorded-calls",
    name: "Recorded callss",
    component: () => import("@/views/CallCenter/RecordedCalls3cx.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "online-agents",
    name: "Online agents",
    component: () => import("@/views/CallCenter/OnlineEmployees.vue"),
    meta: {
      requiresLogged: true,
    },
  },
];

export default CallCenterRoutes;

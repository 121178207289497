


























/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import rules from "./rules";

export default Vue.extend({
  name: "field-password",
  props: {
    value: String,
    retry: Boolean,
    fieldProps: {
      type: Object,
      default: () => ({}),
    },
    fieldRequired: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      password: "",
      rules: [(v: string) => v.length > 7 || this.$t("maxLength") + ""],
      showPassword: false,
    };
  },
  watch: {
    value(val: string) {
      this.password = val;
    },
  },
  mounted() {
    if (this.fieldRequired) {
      (this as any).rules.push(rules.required);
    }
  },
  methods: {
    onEnter(): void {
      this.$emit("enter-pressed");
    },
  },
});

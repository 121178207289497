























/**
 * To star or stop a call
 */
import Vue from "vue";

export default Vue.extend({
  name: "btn-call",
  props: {
    calling: Boolean,
    btnProps: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    onClick() {
      if (this.calling) {
        this.$emit("calling", false);
      } else {
        this.$emit("calling", true);
      }

      this.$emit("click");
    },
  },
});

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import uniqueId from "lodash/uniqueId";

export function mutLoadingRefer(state: any, loading: boolean): void {
  state.loadingRefer = loading;
}
export function mutLoading(state: any, loading: boolean): void {
  state.loading = loading;
}

export function mutProcedureSubtypes(
  state: any,
  procedureSubtypes: any[]
): void {
  state.procedureSubtypes = procedureSubtypes.map((item: any) => ({
    id: item.id,
    name: item.name,
    type: item.type,
    uid: uniqueId(item.name.toLowerCase().replaceAll(" ", "-")),
  }));
}

export function mutNomencators(state: any, nomenclators: any[]): void {
  state.nomenclators = nomenclators;
}
export function mutTreatments(state: any, nomenclators: any[]): void {
  state.treatments = nomenclators;
}
export function mutSurgerys(state: any, nomenclators: any[]): void {
  state.surgerys = nomenclators;
}
export function mutAccessories(state: any, nomenclators: any[]): void {
  state.accessories = nomenclators;
}
export function mutReferences(state: any, nomenclators: any[]): void {
  state.references = nomenclators;
}
export function mutAppointmentTypes(state: any, nomenclators: any[]): void {
  state.appointmenttypes = nomenclators;
}

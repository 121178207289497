import store from "@/store";
import { NavigationGuardNext, Route } from "vue-router";

export const authorizationGuard = (
  to: Route,
  _from: Route,
  next: NavigationGuardNext<Vue>
): void => {
  // if any of the routes in ./router has a meta named 'requiresLogged: true'
  // then check if the user is logged in before routing to this path:
  if ((to.meta as any).requiresLogged) {
    if (store.getters.loggedIn == false) {
      next({ name: "auth" });
    } else {
      next();
    }
  } else if ((to.meta as any).requiresAdmin) {
    if (store.getters.loggedIn == false) {
      next({ name: "auth" });
    } else if (store.getters.isAdmin || store.getters.isSuper) {
      next();
    } else {
      next({ name: "Error 403" });
    }
  } else if ((to.meta as any).requiresSuper) {
    if (store.getters.loggedIn == false) {
      next({ name: "auth" });
    } else if (store.getters.isSuper) {
      next();
    } else {
      next({ name: "Error 403" });
    }
  } else {
    next();
  }
};




































import { mapGetters, mapActions, mapState } from "vuex";

import { GET_SIDE_MENU } from "@/store/getters/types";
import { ACT_GENERAL_CONFIG } from "@/store/actions/types";
export default {
  name: "the-aside",
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

  computed: {
    ...mapGetters([GET_SIDE_MENU]),
    ...mapState("crmSMSModule", ["containunread"]),
  },
  methods: {
    ...mapActions({ act_config: ACT_GENERAL_CONFIG }),
  },
  mounted() {
    (this as any).act_config();
  },
};

import { RouteConfig } from "vue-router";

const DashboardRoutes: Array<RouteConfig> = [
  {
    path: "",
    name: "Dashboard patient status",
    component: () =>
      import(
        /* webpackChunkName: "dashboard-patient-status" */ "@/views/Dashboard/PatientStatus/ProcedureStatusList.vue"
      ),
    meta: {
      requiresLogged: true,
    },
  },
];

export default DashboardRoutes;

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"call-in-progress position-relative",attrs:{"column":""}},[_c('v-layout',{attrs:{"column":"","justify-space-between":""}},[_c('div',{class:_vm.callHold
          ? 'body-1 font-weight-bold text-center orange--text py-4 px-3'
          : 'body-1 font-weight-bold text-center green--text py-4 px-3'},[_vm._v(" "+_vm._s(_vm.callHold ? _vm.$t("Hold", { time: _vm.formatTimeConnected }) : _vm.$t("connected", { time: _vm.formatTimeConnected }))+" ")]),_vm._v(" "+_vm._s(/* Usuario con quien se esta hablando */)+" "),_c('div',{staticClass:"d-flex flex-column align-center justify-center"},[_c('div',{staticClass:"text-h5 font-weight-light"},[_vm._v(" "+_vm._s(_vm.$t("callingTo"))+" ")]),_c('div',{staticClass:"phone dosis font-weight-boldtext-body-2 text--secondary"},[_vm._v(" "+_vm._s(_vm.number)+" ")])])]),_vm._v(" "+_vm._s(/* Botones para funciones de llamada */)+" "),_c('v-toolbar',{staticClass:"mt-10 mb-4",attrs:{"color":"rgba(0,0,0,0)","tag":"div","flat":""}},[_c('div',{staticClass:"d-flex align-center justify-center fill-width"},[_c('btn-on-off-microphone',{class:{
          'v-card--disabled':
            _vm.callCenterProvider.outgoingConnection == null ||
            _vm.callCenterProvider.outgoingConnection == undefined ||
            _vm.callCenterProvider.twilioDeviceProvider.status() != 'busy',
        },model:{value:(_vm.muteCall),callback:function ($$v) {_vm.muteCall=$$v},expression:"muteCall"}}),(
          _vm.callCenterProvider.outgoingConnection != null &&
          _vm.callCenterProvider.outgoingConnection != undefined
        )?[_c('v-scale-transition',[_c('v-btn',{attrs:{"icon":"","color":"warning"},on:{"click":_vm.holdCall}},[_c('v-icon',[_vm._v(_vm._s(_vm.callHold ? "mdi-play" : "mdi-pause"))])],1)],1)]:_vm._e(),_c('btn-call',{staticClass:"mx-3",attrs:{"calling":_vm.m_calling},on:{"calling":_vm.makeCall}})],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
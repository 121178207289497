

































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import { Connection } from "twilio-client";
import { BtnOnOffMicrophone, BtnCall } from "@/components/buttons";

import Call from "@/mixins/call";
import prettyTime from "@/utils/prettyTime";
import { isDevelop } from "@/enviorment";
import { notifyError, notifyInfo } from "@/components/Notification";
import { getAPI } from "@/api/axios-base";

export default Vue.extend({
  name: "call-in-progress",
  mixins: [Call],
  inject: ["callCenterProvider"],
  components: {
    BtnOnOffMicrophone,
    BtnCall,
  },
  props: {
    number: String,
    calling: Boolean,
    leadUuid: String,
  },
  data() {
    return {
      callHold: false,
      resHold: null,
      ringing: false,
      m_calling: true,
      showRecord: false,
      startRecording: false,
      intervalStatusCall: null,
      statusCall: null,
    };
  },
  watch: {
    statusCall(val) {
      if (val == "completed") {
        clearInterval((this as any).intervalStatusCall);
      }
    },

    number(number: string): void {
      if (number != "") this.makeCall(true);
    },
    calling(val: boolean): void {
      this.m_calling = val;
    },
    ringing(val) {
      console.log(val);
    },
  },
  mounted() {
    this.$nextTick(() => {
      // Events
      (this as any).callCenterProvider.twilioDeviceProvider.on(
        "connect",
        this.onConnect
      );
      (this as any).callCenterProvider.twilioDeviceProvider.on(
        "accept",
        this.onProgress
      );
      (this as any).callCenterProvider.twilioDeviceProvider.on(
        "disconnect",
        this.onDisconnect
      );

      this.makeCall(true);
    });
  },
  methods: {
    initGetStatus() {
      if (this.statusCall != "completed") {
        (this as any).intervalStatusCall = setInterval(
          this.getStatusCall,
          1000
        );
      }
    },
    getStatusCall() {
      getAPI
        .get(
          "/twiliocloud/getCall/" +
            (this as any).callCenterProvider.outgoingConnection.parameters
              .CallSid
        )
        .then((res) => {
          this.statusCall = res.data.status;
        });
    },
    // Twilio functions
    prettyTime,
    onConnect(connection: Connection) {
      isDevelop && console.log("onConnect", connection);
    },
    onProgress(connection: Connection) {
      isDevelop && console.log("Progress", connection);
    },

    holdCall() {
      if ((this as any).callHold) {
        const conferenceSid = (this as any).resHold.conferenceSid;
        const customerSid = (this as any).resHold.customerSid;
        getAPI
          .put("/twiliocloud/unhold/" + conferenceSid + "/" + customerSid)
          .then((res) => {
            (this as any).callHold = false;
            this.resHold = res.data;
            (this as any).stopConnectionCount();
            (this as any).startConnectionCount();
          })
          .catch((error) => {
            let mess = error.response.data.message;

            if (mess.includes("[")) {
              mess = mess.replace("[", "");
              mess = mess.replace("]", "");
            }
            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      } else {
        getAPI
          .put(
            "/twiliocloud/hold/" +
              (this as any).callCenterProvider.outgoingConnection.parameters
                .CallSid
          )
          .then((res) => {
            (this as any).callHold = true;
            (this as any).resHold = res.data;
            (this as any).stopConnectionCount();
            (this as any).startConnectionCount();
          })
          .catch((error) => {
            this.$emit("errorcall", error);
            this.ringing = false;
            this.m_calling = false;
            (this as any).stopConnectionCount();
            (
              this as any
            ).callCenterProvider.twilioDeviceProvider.disconnectAll();
          });
      }
    },

    onDisconnect(connection: Connection) {
      isDevelop && console.log("onDisconnect", connection);
      this.ringing = false;
      this.m_calling = false;
      (this as any).stopConnectionCount();
      this.$emit("hangup");
      notifyInfo(this.$t("crmCallCenter.notificationCallFinish"));
    },
    onRinging() {
      this.ringing = true;
      this.m_calling = true;
      isDevelop && console.log("Riging...");
    },
    hangup(): void {
      // Stop the time connected counter
      (this as any).stopConnectionCount();
      if ((this as any).callCenterProvider.outgoingConnection == null) {
        notifyInfo(this.$t("crmCallCenter.notificationCallFinish"));
        this.$emit("hangup");
      }
      const connectionStatus = (
        this as any
      ).callCenterProvider.outgoingConnection.status();

      if (connectionStatus == "pending") {
        (this as any).callCenterProvider.outgoingConnection.reject();
      } else if (connectionStatus == "open") {
        (this as any).callCenterProvider.twilioDeviceProvider.disconnectAll();
      } else {
        (this as any).callCenterProvider.twilioDeviceProvider.disconnectAll();
      }

      notifyInfo(this.$t("crmCallCenter.notificationCallFinish"));
      this.$emit("hangup");
    },
    makeCall(calling: boolean) {
      if (this.number == "") return;
      // Funcion colgar
      if (calling == false) {
        this.hangup();

        return;
      }

      const params = {
        To: this.number,
      };

      this.m_calling = true;
      // Reset the time connected
      (this as any).timeConnected = 0;
      (this as any).startConnectionCount();
      (this as any).callCenterProvider.outgoingConnection = (
        this as any
      ).callCenterProvider.twilioDeviceProvider.connect(params);
      (this as any).callCenterProvider.outgoingConnection.on(
        "ringing",
        this.onRinging
      );
    },
    onStartRecording(payload: boolean) {
      this.startRecording = payload;
      this.showRecord = true;
    },
  },
});




































/**
 * The main toolbar
 */
import Vue from "vue";
// import Search from "../Search.vue";
import {
  BtnUser,
  // BtnUpgrade,
  // BtnInvite,
  // BtnTranslate,
} from "@/components/buttons";
import BtnCallInprogress from "@/views/CallCenter/buttons/BtnCallInprogress.vue";
import { mapMutations } from "vuex";
import BtnChangeStatus from "@/components/buttons/BtnChangeStatus.vue";

export default Vue.extend({
  name: "main-toolbar",
  data() {
    return {
      isAdmin: false,
    };
  },
  components: {
    BtnChangeStatus,
    // Search,
    BtnUser,
    BtnCallInprogress,

    // BtnUpgrade,
    // BtnInvite,
    // BtnTranslate,
  },
  props: {
    appBarProps: Object,
  },
  methods: {
    ...mapMutations(["mutShowDrawer"]),
  },
  mounted() {
    if (this.$store.state.profile.rol == "ADMIN") {
      this.isAdmin = true;
    }
  },
});

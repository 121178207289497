/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { getAPI } from "@/api/axios-base";
import { notifyError } from "@/components/Notification";
import { isDevelop } from "@/enviorment";
import Appointment from "@/models/Appointment";

const CREATE = "/patient/createDate";
const LIST = "/patient/listDates";
const DELETE = "/patient/deleteDate/";
const UPDATE = "/patient/uptadeDate/";
const FILTER = "/patient/filterDate";

export async function actCreateAppointment(
  { commit, dispatch }: any,
  body: Appointment
): Promise<any> {
  commit("mutLoading", true);
  await getAPI.post(CREATE, body);
  await dispatch("actListAppointment");
  commit("mutLoading", false);
}

export async function actRemoveAppointment(
  { commit, dispatch }: any,
  uuid: string
): Promise<any> {
  commit("mutLoading", true);

  await getAPI.delete(DELETE + uuid);
  await dispatch("actListAppointment");
  commit("mutLoading", false);
}

export async function actUpdateAppointment(
  { commit, dispatch }: any,
  { uuid, body }: { uuid: string; body: Appointment }
): Promise<any> {
  commit("mutLoading", true);
  await getAPI.put(UPDATE + uuid, body);
  await dispatch("actListAppointment");
  commit("mutLoading", false);
}

export async function actListAppointment({ commit }: any): Promise<any> {
  commit("mutLoading", true);

  try {
    const resutlData = (await getAPI(LIST)).data;

    commit("mutAppointmets", resutlData);
    commit("mutLoading", false);
  } catch (error: any) {
    isDevelop && console.log("actListAppointment", error);
    commit("mutLoading", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export async function actFilterAppointment(
  { commit, dispatch }: any,
  body: string
): Promise<any> {
  commit("mutLoading", true);
  await getAPI.post(FILTER, body);
  await dispatch("actListAppointment");
  commit("mutLoading", false);
}

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import { getAPI } from "@/api/axios-base";
import { amountDues, Payment } from "@/utils/amountDues";
import { findIndex, forEach, groupBy } from "lodash";
import moment from "moment";

interface Procedure {
  id: number;
  type: string;
  dasboardStatus: string;
  procedure_type: string;
  surgicalDate: string;
  surgeryType: {
    name: string;
  };
  treatmentType: {
    name: string;
  };
  patient: {
    fullname: string;
    uuid: string;
  };
  payments: Payment[];
  contract: any;
  total: number;
  deb: number;
  totalTreatment: number;
  createAt: string;
}

interface ProceduresRequest {
  procedures: Procedure[];
  filters?: string;
}
interface ActionsRequest {
  uuid?: string;
  filters?: string;
  employeeUuid?: string;
  patientUuid?: string;
  procedure_type?: string;
}

export function actProcedures(
  { commit, state, getters }: any,
  procedureReq: ProceduresRequest
): any[] {
  const groups = [
    {
      id: "NEW",
      title: "New",
      color: "#ab47bc",
      children: [],
    },
    {
      id: "EVALUATION",
      title: "Evaluation",
      color: "#ec407a",
      children: [],
    },
    {
      id: "NO_INTERESTED",
      title: "Not interested",
      color: "#e91e63",
      children: [],
    },
    {
      id: "PENDING",
      title: "Pending",
      color: "#f44336",
      children: [],
    },
    {
      id: "PAYMENT_IN_PROCCESS",
      title: "Payment in Process",
      color: "#26a69a",
      children: [],
    },
    {
      id: "FINISHED_PAYMENT",
      title: "Finished payment",
      color: "#43a047",
      children: [],
    },
  ];

  const proceduresGrouped = groupBy(procedureReq.procedures, "dasboardStatus");

  forEach(proceduresGrouped, (procedures: Procedure[], groupName: string) => {
    // Para cada procedimiento tomar los datos
    const procedureChildren = procedures.map((procedure: Procedure) => {
      const total = procedure.totalTreatment;
      let payments = 0.0;
      if (procedure.payments != undefined) {
        procedure.payments.forEach((paids: Payment) => {
          const p = paids.status == "CONFIRMED" ? Number(paids.amount) : 0.0;
          payments = payments + p;
        });
      }

      let subtype;

      if (procedure.surgeryType != null && procedure.surgeryType != undefined) {
        subtype = procedure.surgeryType.name;
      } else if (
        procedure.treatmentType != null &&
        procedure.treatmentType != undefined
      ) {
        subtype = procedure.treatmentType.name;
      }
      const due = total - payments;

      let datas: any = "";
      if (procedure.surgicalDate != null) {
        datas = moment(procedure.surgicalDate).utc().format("D MMMM YYYY");
      } else {
        datas = null;
      }
      const [year, month, date] = procedure.createAt.split("-");
      const day = date.substr(-20, 2);
      const data = day + "-" + month + "-" + year;
      const procedureData = {
        type: procedure.procedure_type,
        subtype: subtype || "Subtype not defined",
        patientName: procedure.patient.fullname,
        patientUuid: procedure.patient.uuid,
        surgicalDate: datas,
        total: total,
        deb: due,
        createAt: data,
      };

      return { id: procedure.id, ...procedureData };
    });
    // Dada la posicion poner los childrens
    const groupIndex = findIndex(groups, (item) => item.id == groupName);

    (groups as any)[groupIndex].children = procedureChildren;
  });

  return groups;
}

export async function actUpdateProcedureStatus(
  { commit }: any,
  {
    id,
    status,
    activeStatus,
  }: { id: number; status: string; activeStatus: "ACTIVE" | "CANCELED" }
): Promise<any> {
  await getAPI.post(`/sprocedures/updateProcedure/${id}`, {
    dasboardStatus: status,
  });
}
export async function actRemoveProcedureStatus(
  { commit }: any,
  { id }: { id: number }
): Promise<any> {
  const responseData = (
    await getAPI.delete(`/sprocedures/cancelProcedure/${id}`)
  ).data;
}

export async function actFilterProcedureByPatient(
  { dispatch }: any,
  actionReq: ActionsRequest
): Promise<Procedure[]> {
  const responseData = (await getAPI(`/patient/getPatient/${actionReq.uuid}`))
    .data;
  const proceduresEntities = responseData.proceduresEntity.map(
    (procedure: any) => ({
      patient: {
        fullname: responseData.fullname,
        uuid: responseData.uuid,
      },
      ...procedure,
    })
  );
  const procedures = dispatch("actProcedures", {
    procedures: proceduresEntities,
    filters: actionReq.filters,
  });

  return procedures;
}

export async function actFilterDashboardProcedure(
  { dispatch }: any,
  actionReq: ActionsRequest
): Promise<Procedure[]> {
  const responseData = (
    await getAPI.post(`/sprocedures/filterProcedures`, actionReq)
  ).data;

  const proceduresEntities = responseData.map((procedure: any) => ({
    patient: {
      fullname: procedure.patient.fullname,
      uuid: procedure.patient.uuid,
    },
    ...procedure,
  }));

  let proceduresActives;
  proceduresActives = proceduresEntities.filter(
    (procedur: any) => procedur.activeStatus == "ACTIVE"
  );
  if (actionReq.filters == "Surgerys") {
    proceduresActives = proceduresActives.filter(
      (proced: any) => proced.surgeryType != null
    );
  }
  if (actionReq.filters == "Treatments") {
    proceduresActives = proceduresActives.filter(
      (proced: any) => proced.treatmentType != null
    );
  }

  const procedures = dispatch("actProcedures", {
    procedures: proceduresActives,
    filters: actionReq.filters,
  });

  return procedures;
}

export async function actProcedureList(
  { dispatch }: any,
  actionReq: ActionsRequest
): Promise<Procedure[]> {
  const responseData = (await getAPI("/sprocedures/list")).data;
  let proceduresActives;

  proceduresActives = responseData.filter(
    (procedur: any) => procedur.activeStatus == "ACTIVE"
  );
  if (actionReq.filters == "Surgerys") {
    proceduresActives = proceduresActives.filter(
      (proced: any) => proced.surgeryType != null
    );
  }
  if (actionReq.filters == "Treatments") {
    proceduresActives = proceduresActives.filter(
      (proced: any) => proced.treatmentType != null
    );
  }
  const procedures = dispatch("actProcedures", {
    procedures: proceduresActives,
    filters: actionReq.filters,
  });

  return procedures;
}

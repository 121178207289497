function formatPhone(phoneValue: string): any {
  let phone = phoneValue;

  for (let i = 0; i < phoneValue.length; i++) {
    const p: any = phoneValue[i];
    if (isNaN(p)) {
      phone = phone.replaceAll(p, "");
    }
  }

  return phone;
}

export default formatPhone;
